import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();

$(document).ready(function () {

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('#logo').fadeOut();
    } else {
      $('#logo').fadeIn();
    }
  });

 
  // Modal
  // Click function for show the Modal

  $(".show").on("click", function () {
    $(".mask").addClass("active");
  });

  // Function for close the Modal

  function closeModal() {
    $(".mask").removeClass("active");
  }

  // Call the closeModal function on the clicks/keyboard

  $(".close, .mask").on("click", function () {
    closeModal();
  });

  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      closeModal();
    }
  });

  // Modal nav
  // Click function for show the Modal

  $(".show-nav").on("click", function () {
    $(".mask-nav").addClass("active");
  });

  // Function for close the Modal

  function closeModalNav() {
    $(".mask-nav").removeClass("active");
  }

  // Call the closeModal function on the clicks/keyboard

  $(".close-nav, .mask-nav").on("click", function () {
    closeModalNav();
  });

  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      closeModal();
    }
  });


  $("#navigation button").click(function () {
    const toggleMenu = document.querySelector("#navigation button");
    const menu = document.querySelector("#navigation ul");
    const menuFooter = document.querySelector('.menu-footer');
    const menuContact = document.querySelector('.nav-contact');
    const open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
    menuFooter.hidden = !menuFooter.hidden;
    menuContact.hidden = !menuContact.hidden;
    $('#navigation ul#menu').toggleClass("menu-bg");
    $('#navigation .logo-circle').toggleClass("logo-circle-bg");
    $('#navigation .fb-nav').toggleClass("fb-nav-icon");
    $('#navigation .insta-nav').toggleClass("insta-nav-icon");
    $('.main-nav-container').toggleClass("open");
    // Pizza Button 
    let pizzaBtn = ($('button img').attr('src') === '/site/templates/dist/assets/img/svg/menu-close.svg') ? '/site/templates/dist/assets/img/svg/menu-open.svg' : '/site/templates/dist/assets/img/svg/menu-close.svg';
    $('button img').attr('src', pizzaBtn);
  })

  $("#btnAbout").click(function () {
    const toggleMenu = document.querySelector("#navigation button");
    const menu = document.querySelector("#navigation ul");
    const menuFooter = document.querySelector('.menu-footer');
    const menuContact = document.querySelector('.nav-contact');
    const open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
    menuFooter.hidden = !menuFooter.hidden;
    menuContact.hidden = !menuContact.hidden;
    $('#navigation ul#menu').toggleClass("menu-bg");
    $('#navigation .logo-circle').toggleClass("logo-circle-bg");
    $('#navigation .fb-nav').toggleClass("fb-nav-icon");
    $('#navigation .insta-nav').toggleClass("insta-nav-icon");
    $('.main-nav-container').toggleClass("open");
    // Burger Button 
    let burgerBtn = ($('button img').attr('src') === '/site/templates/dist/assets/img/svg/menu-close.svg') ? '/site/templates/dist/assets/img/svg/menu-open.svg' : '/site/templates/dist/assets/img/svg/menu-close.svg';
    $('button img').attr('src', burgerBtn);
  })

  // Slider  -  Front page
  var swiper = new Swiper('.swiper-front', {
    effect: 'fade',
    speed: 1500,
    autoplay: {
      delay: 2000,
    },
  });

  // Slider  -  Menu page
  var swiper = new Swiper('.swiper-menu', {
    effect: 'fade',
    speed: 1500,
    autoplay: {
      delay: 2000,
    },
  });

  // Slider on mobile - home page by team
  var swiper = new Swiper('.swiper-team-mobile', {
    autoplay: {
      delay: 3000,
    },
    fadeEffect: {
      crossFade: true
    },
    effect: 'fade',
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // Slider on desktop - home page by team
  var swiper = new Swiper('.swiper-team-desktop', {
    slidesPerView: 4,
    spaceBetween: 30,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  //  var swiper = new Swiper('.swiper-team-desktop', {
  //   slidesPerView: 4,
  //   spaceBetween: 30,
  //   // autoplay: {
  //   //   delay: 3000,
  //   // },

  //   loop: true,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  // });

  // Slider on mobile - catering page
  var swiper = new Swiper('.swiper-catering-service', {
    autoplay: {
      delay: 3000,
    },
    effect: 'fade',
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // matchMedia mediaquery define to GSAP animation on homapage
  var x = window.matchMedia("(max-width: 767px)")

  function myFunction(x) {
    if (x.matches) { // If media query matches >> Mobile
      $('.home-header').each(function () {
        let tl = new TimelineMax();
        let mobileAnimation = $('.mobile-animation');
        let covMobile = $('.mobile-animation').find('.cover');
        let covBig = $('.cover-big');
        let covMobileSecond = $('.mobile-animation').find('.cover-second');

        tl.from(mobileAnimation, .7, {
          opacity: 0,
          ease: Power2.easeOut
        });
      });
    } else {
      // GSAP animation to home site desktop
      $('.home-header').each(function () {
        let tl = new TimelineMax();
        let leftSide = $('.left-side');
        let rightSide = $('.right-side');

        tl.from(leftSide, .7, {
          opacity: 0,
          ease: Power2.easeOut
        }, "reveal");
        tl.from(rightSide, .7, {
          opacity: 0,
          ease: Power2.easeOut
        }, "reveal");
      })
    }
  }

  myFunction(x) // Call listener function at run time
  x.addListener(myFunction) // Attach listener function on state changes
});